import { add, isBefore, sub } from 'date-fns'
import Validation from '../lib/validation'
import { constants } from '../schemaConfig'
import {
  orderTypes,
  bevelPositions,
  frameTypes,
  R,
  L,
  orderStatuses,
  appTypeConfig,
} from '../config'
import {
  isRequiredInIndividualMenu,
  isShownInIndividualMenu,
  pairValidation,
  validation,
  FEMALE,
  MALE,
  validateRange,
  validateRequired,
  checkNumbersValidity,
} from '../validationHelpers'
import { getFinalRadiiByShapeAndLenses } from '../lib/vca'
import i18n from '../i18n/i18n'
import { isRxDesignCustomDiameter } from './utils'
import { isCombinationValid, isExpressDisabled, getIsFrameValid } from '../catalogExtensions'

const log = console.getLogger('VALIDATIONS')

const {
  bvd,
  panto,
  frameBowAngle,
  proglen,
  cvd,
  readdist,
  laterality,
  fvs,
  nvs,
  contrastSv,
  designType,
  inset,
  farObjectDistance,
} = constants

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export const isOrderMatureEnoughToArchive = order => {
  if (
    [orderStatuses.DONE, orderStatuses.CANCELLED, orderStatuses.ARCHIVED].includes(order.status)
  ) {
    return true
  }

  const oneMonthBeforeNow = sub(new Date(), {
    months: 1,
  })

  if (
    orderStatuses.SENT === order.status &&
    order.ftpSentAt &&
    isValidDate(new Date(order.ftpSentAt)) &&
    isBefore(new Date(order.ftpSentAt), oneMonthBeforeNow)
  ) {
    return true
  }
  if (
    orderStatuses.AT_MANUFACTURER === order.status &&
    order.lastStatusUpdateAt &&
    isValidDate(new Date(order.lastStatusUpdateAt)) &&
    isBefore(new Date(order.lastStatusUpdateAt), oneMonthBeforeNow)
  ) {
    return true
  }

  return false
}

const isApproxFilled = (values, fieldKey) => !!values[`${fieldKey}Approx`]

const createError = ({ key, title, message }) => ({
  key,
  title,
  message,
  // indicates the error was created by us and can be shown to user on fronted (if sent from server)
  showOnFrontend: true,
})

const singleValidationConfig = {
  reference: {
    isRequired: true,
    context: FEMALE,
  },
  panto: {
    translationKey: 'pantoscopic tilt',
    isRequired: ({ individualMenu, ...rest }) =>
      !isApproxFilled(rest, panto) && isRequiredInIndividualMenu(individualMenu, panto),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, panto),
    context: MALE,
  },
  frameBowAngle: {
    translationKey: 'face form angle',
    isRequired: ({ individualMenu, ...rest }) =>
      !isApproxFilled(rest, frameBowAngle) &&
      isRequiredInIndividualMenu(individualMenu, frameBowAngle),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, frameBowAngle),
    context: FEMALE,
  },

  readdist: {
    translationKey: 'reading distance',
    isRequired: ({ individualMenu, ...rest }) =>
      !isApproxFilled(rest, readdist) && isRequiredInIndividualMenu(individualMenu, readdist),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, readdist),
    context: MALE,
  },

  farObjectDistance: {
    translationKey: 'farObjectDistance',
    isRequired: ({ individualMenu }) => 
      isRequiredInIndividualMenu(individualMenu, farObjectDistance),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, farObjectDistance),
    context: MALE,
  },

  // reize only
  cvd: {
    translationKey: 'reize; Cvd',
    isRequired: ({ individualMenu, ...rest }) =>
      !isApproxFilled(rest, cvd) && isRequiredInIndividualMenu(individualMenu, cvd),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, cvd),
    context: MALE,
  },

  fvs: {
    translationKey: 'reize; Far vision sensitivity',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, fvs),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, fvs),
    context: MALE,
  },
  nvs: {
    translationKey: 'reize; Near vision sensitivity',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, nvs),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, nvs),
    context: MALE,
  },
  contrastSv: {
    translationKey: 'reize; Contrast sensitivity',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, contrastSv),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, contrastSv),
    context: MALE,
  },
  designType: {
    translationKey: 'reize; Design type',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, designType),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, designType),
    context: MALE,
  },

  laterality: {
    translationKey: 'reize; Laterality',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, laterality),
    context: MALE,
  },

  proglen: {
    translationKey: 'Progression length',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, proglen),
    context: MALE,
  },

  // diameter je tu považován jako single key z důvodu validace
  // závislé na cto, které nemusí být nastaveno pro obě strany
  diameterPhysicalR: {
    translationKey: 'lens diameter',
    isRequired: ({ orderType, rightLensEnabled, isCtoCapableR, ctoR }) =>
      rightLensEnabled && (orderType === orderTypes.DIAMETER_ONLY || (isCtoCapableR && !ctoR)),
    context: MALE,
  },
  diameterPhysicalL: {
    translationKey: 'lens diameter',
    isRequired: ({ orderType, leftLensEnabled, isCtoCapableL, ctoL }) =>
      leftLensEnabled && (orderType === orderTypes.DIAMETER_ONLY || (isCtoCapableL && !ctoL)),
    context: MALE,
  },
}

const pairValidationConfig = {
  sphere: {
    isRequired: true,
    context: FEMALE,
  },
  pd: {
    isRequired: ({ orderType }) => orderType !== orderTypes.DIAMETER_ONLY,
    checkRange: ({ orderType }) => orderType !== orderTypes.DIAMETER_ONLY,
    context: FEMALE,
  },
  height: {
    isRequired: ({ orderType }) => orderType !== orderTypes.DIAMETER_ONLY,
    checkRange: ({ orderType }) => orderType !== orderTypes.DIAMETER_ONLY,
    context: FEMALE,
  },
  decX: {
    // isRequired: ({ orderType }) => orderType === orderTypes.DIAMETER_ONLY,
    checkRange: true,
    context: FEMALE,
  },
  decY: {
    // isRequired: ({ orderType }) => orderType === orderTypes.DIAMETER_ONLY,
    checkRange: true,
    context: FEMALE,
  },
  lens: {
    translationKey: 'lens type',
    isRequired: true,
    context: MALE,
  },
  axis: {
    checkRange: true,
    context: FEMALE,
  },
  base1: {
    checkRange: true,
  },
  base2: {
    checkRange: true,
  },
  minEdgeThickness: {
    checkRange: ({ orderType }) => orderType === orderTypes.DIAMETER_ONLY,
    context: FEMALE,
  },
  minCenterThickness: {
    checkRange: ({ orderType }) => orderType === orderTypes.DIAMETER_ONLY,
    context: FEMALE,
  },
  // přesunuto do single keys z důvodu validace dle cto
  // diameterPhysical: {
  //   translationKey: 'lens diameter',
  //   isRequired: ({ orderType }) => orderType === orderTypes.DIAMETER_ONLY,
  //   context: MALE,
  // },
  bvd: {
    translationKey: 'back vertex distance',
    isRequired: ({ individualMenu }) => isRequiredInIndividualMenu(individualMenu, bvd),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, bvd),
    context: FEMALE,
  },

  inset: {
    translationKey: 'inset',
    isRequired: ({ individualMenu, ...rest }) =>
      !isApproxFilled(rest, inset) && isRequiredInIndividualMenu(individualMenu, inset),
    checkRange: ({ individualMenu }) => isShownInIndividualMenu(individualMenu, inset),
    context: MALE,
  },
}

const singleValidationConfig2 = {
  frameType: {
    isRequired: ({ orderType }) =>
      [orderTypes.EDGING, orderTypes.EDGING_COMPLETION].includes(orderType),
  },
  bevelModifier: {
    translationKey: 'specification',
    isRequired: ({ bevelPosition }) =>
      [bevelPositions.FRONT, bevelPositions.BACK, bevelPositions.PERCENT].includes(bevelPosition),
    checkRange: ({ bevelPosition }) =>
      [bevelPositions.FRONT, bevelPositions.BACK, bevelPositions.PERCENT].includes(bevelPosition),
  },
  grooveWidth: {
    checkRange: ({ frameType }) =>
      [frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(frameType),
  },
  grooveDepth: {
    checkRange: ({ frameType }) =>
      [frameTypes.NYLOR, frameTypes.GROOVEDDRILLED].includes(frameType),
  },
  dbl: {
    checkRange: true,
    isRequired: true,
  },
}

const pairValidationConfig2 = {
  minEdgeThickness: {
    checkRange: true,
    context: FEMALE,
  },
  minCenterThickness: {
    checkRange: true,
    context: FEMALE,
  },
  hbox: {
    checkRange: true,
    context: MALE,
  },
  vbox: {
    checkRange: true,
    context: MALE,
  },
}

if (appTypeConfig.isShapeSizeAdjustmentEnabled) {
  singleValidationConfig2.shapeSizeAdjustment = {
    checkRange: true,
  }
}

if (appTypeConfig.isFrameCurveStep2Visible) {
  pairValidationConfig2.frameCurve = {
    checkRange: true,
    translationKey: 'face form angle',
  }
}

const validateCto = ({ allData, errors }) => {
  if (!appTypeConfig.isCtoEnabled) return
  const { ctoR, ctoL, rightLensEnabled, leftLensEnabled, lensR, lensL } = allData || {}
  // if lenses are same, but RX design is selected only for one side, show error
  if (rightLensEnabled && leftLensEnabled && lensR === lensL) {
    if ((ctoR && !ctoL) || (!ctoR && ctoL)) {
      const invalidCombinationError = createError({
        key: 'invalid RX combination',
        title: 'Invalid RX combination',
        message: i18n.t('RX design cannot be selected for only one side'),
      })
      errors.push(invalidCombinationError)
    }
  }
}

export const validateStep1 = ({
  dataToValidate = {},
  allData,
  customPairConfig,
  mandatoryOptionsR,
  mandatoryOptionsL,
  catalogExtensions,
}) => {
  const validator = new Validation(dataToValidate)
  const { leftLensEnabled, rightLensEnabled } = dataToValidate

  checkNumbersValidity({ dataToValidate, validator })

  // eslint-disable-next-line
  const sides = []
  if (rightLensEnabled) sides.push(R)
  if (leftLensEnabled) sides.push(L)
  sides.forEach(side => {
    const diameterPhysicalKey = `diameterPhysical${side}`
    if (
      customPairConfig &&
      customPairConfig[diameterPhysicalKey] &&
      dataToValidate[diameterPhysicalKey] &&
      allData[`isDiameterCustom${side}`] &&
      allData.orderType === orderTypes.DIAMETER_ONLY
    ) {
      validateRange({
        validator,
        fieldName: diameterPhysicalKey,
        translationKey: 'lens diameter',
        context: MALE,
        ...customPairConfig[diameterPhysicalKey],
      })
    }
    const mandatoryOptions = side === L ? mandatoryOptionsL : mandatoryOptionsR
    if (mandatoryOptions) {
      if (mandatoryOptions.coating && !allData[`coating${side}`]) {
        validateRequired({
          validator,
          translationKey: 'coating',
          fieldName: `coating${side}`,
          context: MALE,
        })
      }
      if (mandatoryOptions.color && !allData[`color${side}`]) {
        validateRequired({
          validator,
          translationKey: 'color',
          fieldName: `color${side}`,
          context: FEMALE,
        })
      }
    }
    if (dataToValidate[`cylinder${side}`] || dataToValidate[`cylinder${side}`] === 0) {
      validateRequired({
        validator,
        translationKey: 'axis',
        fieldName: `axis${side}`,
        context: FEMALE,
      })
    }
  })

  pairValidation({
    validator,
    specification: pairValidationConfig,
    data: allData || dataToValidate,
    leftLensEnabled,
    rightLensEnabled,
    individualMenu: allData.individualMenu,
  })

  validation({
    validator,
    specification: singleValidationConfig,
    data: allData || dataToValidate,
    individualMenu: allData.individualMenu,
  })

  const errors = validator.validate()

  validateCto({ allData, errors })

  sides.forEach(side => {
    // check catalog extensions
    // this will work only on backend, but it should not be possible to
    // select invalid combination on frontend
    const colorCode = allData[`colorCode${side}`]
    const coatingCode = allData[`coatingCode${side}`]

    if (colorCode && coatingCode) {
      // check that combination is ok in terms of catalog extensions
      if (!isCombinationValid({ colorCode, coatingCode, catalogExtensions })) {
        const invalidCombinationError = createError({
          key: 'invalidOptionCombination',
          title: 'Invalid option combination',
          message: 'Selected coating and color can not be ordered together.',
        })
        errors.push(invalidCombinationError)
        console.notifyError(invalidCombinationError, {
          colorCode,
          coatingCode,
          message: 'This means FE validation is not working correctly',
        })
      }
    }

    if (coatingCode && allData.isExpress) {
      // check if order can be order with express
      if (isExpressDisabled({ coatingCode, catalogExtensions })) {
        const invalidCombinationError = createError({
          key: 'invalidOptionCombination',
          title: 'Invalid option combination',
          message: 'Selected coating can not be ordered with express delivery.',
        })
        errors.push(invalidCombinationError)
        console.notifyError(invalidCombinationError, {
          coatingCode,
          message: 'This means FE validation is not working correctly',
        })
      }
    }
  })

  return errors
}

export const validateStep2 = ({
  dataToValidate = {},
  allData,
  selectedVca,
  currentLensR,
  currentLensL,
  catalogExtensions,
}) => {
  const validator = new Validation({
    ...dataToValidate,
    // vca values mapping is needed for validation of the whole step,
    // direct field validation works without it since you can pass value directly
    dbl: selectedVca?.dbl ?? dataToValidate.dbl,
    frameCurveR: selectedVca?.frameCurveR ?? dataToValidate.frameCurveR,
    frameCurveL: selectedVca?.frameCurveL ?? dataToValidate.frameCurveL,
  })

  log.trace({ allData, dataToValidate, selectedVca }, 'validateStep2 data - check in loki')

  const { leftLensEnabled, rightLensEnabled } = dataToValidate

  checkNumbersValidity({ dataToValidate, validator })

  pairValidation({
    validator,
    specification: pairValidationConfig2,
    data: allData || dataToValidate,
    leftLensEnabled,
    rightLensEnabled,
  })

  validation({
    validator,
    specification: singleValidationConfig2,
    data: allData || dataToValidate,
  })

  const errors = validator.validate()

  // check if frame is valid according to catalog extensions
  if (allData.orderType === orderTypes.WITH_FRAME) {
    const isFrameValid = getIsFrameValid({
      order: allData,
      frame: selectedVca,
      catalogExtensions,
      currentLensR,
      currentLensL,
    })

    if (!isFrameValid) {
      errors.push(
        createError({
          key: 'frameNotValid',
          title: 'Frame is invalid',
          message: i18n.t('Frame is not available for selected product'),
        }),
      )
    }
  }

  // console.log('validator.validate()', errors, selectedVca)

  /* todo mělo by stačit (!selectedVca), ale při server validaci se posílá
  vca vždy (kopie objednávky) viz order.js 86, tedy toto je workaround, jinak to padá */
  if (!selectedVca?.shapeData) {
    const title = i18n.t('You have to pick a shape')
    errors.push(
      createError({
        key: 'emptyShape',
        title,
        message: title,
      }),
    )
    console.log('not shape')
  } else {
    const values = allData || dataToValidate
    // console.log('validations = values', values)
    const radii = getFinalRadiiByShapeAndLenses({
      vca: selectedVca,
      lensR: currentLensR,
      lensL: currentLensL,
      order: values,
    })

    if (currentLensR || currentLensL) {
      let isDiameterOkR = true
      let isDiameterOkL = true

      // TODO: pro Michala - server side validace toto neumi...

      if (currentLensR && values.rightLensEnabled) {
        isDiameterOkR = false
        // if custom diameter is selected - validate its bigger than smallest enclosing circle
        if (isRxDesignCustomDiameter(values.isCtoCapableR, values.ctoR)) {
          if (values.diameterPhysicalR > radii.r * 2) {
            isDiameterOkR = true
          }
        } else {
          // otherwise do standard validation - there is bigger diameter in catalog than the calculated one
          currentLensR.diameters.forEach(d => {
            if (d.physical > radii.r * 2) {
              isDiameterOkR = true
            }
          })
        }
      }

      if (currentLensL && values.leftLensEnabled) {
        isDiameterOkL = false
        // if custom diameter is selected - validate its bigger than smallest enclosing circle
        if (isRxDesignCustomDiameter(values.isCtoCapableL, values.ctoL)) {
          console.log('STEP2 CTO validation!', values.diameterPhysicalL)
          if (values.diameterPhysicalL > radii.l * 2) {
            isDiameterOkL = true
          }
        } else {
          // otherwise do standard validation - there is bigger diameter in catalog than the calculated one
          currentLensL.diameters.forEach(d => {
            if (d.physical > radii.l * 2) {
              isDiameterOkL = true
            }
          })
        }
      }

      // co kdyz objednavam pouze jednu cocku --> hotovo
      // TODO: k zamysleni - jak tohle napsat hezceji
      // TODO: co kdyz to bude ok, vratim se do kroku 1, a pak preskocim nakonec???
      let errorTitle = ''
      if (!isDiameterOkR) {
        errorTitle = 'error - right lens'
      }
      if (!isDiameterOkL) {
        errorTitle = 'error - left lens'
      }
      if (!isDiameterOkL && !isDiameterOkR) {
        errorTitle = 'error - both lenses'
      }
      const message = 'error - max shape diameter exceeded'
      if (!isDiameterOkL || !isDiameterOkR) {
        // console.log('radii.l', radii.l, currentLensL.diameters)
        errors.push(
          createError({
            key: 'maxShapeDiameterExceeded',
            title: errorTitle,
            message,
          }),
        )
      }
    }
  }
  // end of shape validation
  // console.log('final step 2 errors', errors)
  return errors
}

// export const validateStep3 = (props) => {
//   const { orderType } = props.allData
//   if (orderType === orderTypes.DIAMETER_ONLY) {
//     return validateStep1(props)
//   }
//   return validateStep1(props) && validateStep2(props)
// }
// export const validateStep4 = (props) => validateStep1(props) && validateStep2(props)

// export default validateStep1

// krok 2
/**
 * validovat:
 * - ze je vybrany typ obruby
 * - pozice fazety
 * -- mm % zepredu maji bevelModifier vyplnen a v rozsahu 1 - 99 %  nebo 0 - 3.5 mm?
 * -- drazka - hloubka - 0.4 - 0.8      sirka 0.6 - 1.2
 * - dbl je 1 - 50
 *
 */
