import React from 'react'
import Col from '../../Col'
import SummaryTable from '../SummaryTable'
import { constants } from '../../../../common/schemaConfig'
import { getTextValue } from './utils'
import { deleteIndividualMenuValuesIfNotUsed } from '../../../../common/orders/utils'
import { appTypeConfig } from '../../../../common/config'
import { SwitchByAppType } from '../..'

const { individualMenus } = appTypeConfig

const ReizeIndividualSummary = props => {
  const { t, isPrint, values } = props
  return (
    <SummaryTable
      {...props}
      title={t('individual params')}
      isHiddenIfEmpty={isPrint}
      values={values}
      render={children => <Col>{children}</Col>}
      isDisplayedInColumn
      columnConfig={{
        header: { width: 'max-content' },
        data: { minWidth: '5rem', maxWidth: 'auto' },
      }}
      data={[
        {
          header: t('face form angle'),
          fieldName: constants.frameBowAngle,
          renderValue: ({ value, values }) =>
            values.frameBowAngleApprox ? t(values.frameBowAngleApprox) : getTextValue(value),
        },
        {
          header: t('reize; Cvd'),
          fieldName: constants.cvd,
          renderValue: ({ value, values }) =>
            values.cvdApprox ? t(values.cvdApprox) : getTextValue(value),
        },
        {
          header: t('pantoscopic tilt'),
          fieldName: constants.panto,
          renderValue: ({ value, values }) =>
            values.pantoApprox ? t(values.pantoApprox) : getTextValue(value),
        },
        {
          header: values.readdistApprox
            ? t('reize; Body height of wearer')
            : t('reize; Reading distance'),
          fieldName: constants.readdist,
          renderValue: ({ value, values }) =>
            values.readdistApprox ? t(values.readdistApprox) : getTextValue(value),
        },
        // {
        //   header: t('reize; Laterality'),
        //   fieldName: constants.laterality,
        //   renderValue: ({ value }) => t(lateralities[value]),
        // },
        {
          header: t('Progression length'),
          fieldName: constants.proglen,
        },
        {
          header: t('reize; Far vision sensitivity'),
          fieldName: constants.fvs,
        },
        {
          header: t('reize; Near vision sensitivity'),
          fieldName: constants.nvs,
        },
        {
          header: t('reize; Contrast sensitivity'),
          fieldName: constants.contrastSv,
        },
        {
          header: t('reize; Design type'),
          fieldName: constants.designType,
        },
      ]}
    />
  )
}

const JzoIndividualSummary = props => {
  const { t, values } = props

  const menuConf = individualMenus[values?.individualMenu] || {}
  const hasProglen = menuConf.proglen && values[constants.proglen]

  const data = [
    {
      header: t('face form angle', { context: 'individual' }),
      fieldName: constants.frameBowAngle,
      renderValue: ({ value, values }) =>
        values.frameBowAngleApprox ? t(values.frameBowAngleApprox) : getTextValue(value),
    },
    {
      header: t('bvd'),
      fieldName: `${constants.bvd}R`,
      renderValue: ({ value, values }) =>
        values.bvdApprox ? t(values.bvdApprox) : getTextValue(value),
    },
    {
      header: t('pantoscopic tilt'),
      fieldName: constants.panto,
      renderValue: ({ value, values }) =>
        values.pantoApprox ? t(values.pantoApprox) : getTextValue(value),
    },
    {
      header: values.readdistApprox ? t('Body height of wearer') : t('reading distance'),
      fieldName: constants.readdist,
      renderValue: ({ value, values }) =>
        values.readdistApprox ? t(values.readdistApprox) : getTextValue(value),
    },
    {
      header: t('farObjectDistance'),
      fieldName: constants.farObjectDistance,
    },
    {
      header: t('inset'),
      fieldName: 'inset',
      renderValue: ({ values }) =>
        `${getTextValue(values.insetR)} / ${getTextValue(values.insetL)}`,
    },
  ]

  if (hasProglen) { 
    data.push({
      header: t('Progression length'),
      fieldName: constants.proglen,
    })
  }

  return (
    <SummaryTable
      {...props}
      title={t('individual params')}
      isDisplayedInColumn
      render={children => <Col>{children}</Col>}
      data={data}
    />
  )
}

const OmegaIndividualSummary = props => {
  const { t } = props
  return (
    <SummaryTable
      {...props}
      title={t('individual params')}
      isDisplayedInColumn
      render={children => <Col>{children}</Col>}
      data={[
        {
          header: t('back vertex distance'),
          fieldName: `${constants.bvd}R`, // both sides are always same
        },
        {
          header: t('face form angle'),
          fieldName: constants.frameBowAngle,
        },
        {
          header: t('pantoscopic tilt'),
          fieldName: constants.panto,
        },
      ]}
    />
  )
}

const JaikudoIndividualSummary = props => {
  const { t, values } = props
  const menuConf = individualMenus[values.individualMenu]
  const proglenOptions = menuConf?.proglen?.options || []
  return (
    <SummaryTable
      {...props}
      title={t('individual params')}
      isDisplayedInColumn
      render={children => <Col>{children}</Col>}
      data={[
        {
          header: t('back vertex distance'),
          fieldName: `${constants.bvd}R`, // both sides are always same
        },
        {
          header: t('face form angle'),
          fieldName: constants.frameBowAngle,
        },
        {
          header: t('pantoscopic tilt'),
          fieldName: constants.panto,
        },
        {
          header: t('Progression length'),
          fieldName: constants.proglen,
          renderValue: ({ value }) => {
            const option = proglenOptions.find(o => o.value === value)
            return option ? option.label : getTextValue(value)
          },
        },
      ]}
    />
  )
}

const individualMenuVariants = {
  reize: ReizeIndividualSummary,
  jzo: JzoIndividualSummary,
  omega: OmegaIndividualSummary,
  jaikudo: JaikudoIndividualSummary,
}

const IndividualParamsSummary = props => {
  const { isCreatingOrder } = props
  let { values } = props

  if (isCreatingOrder) {
    values = deleteIndividualMenuValuesIfNotUsed(values)
  }

  return <SwitchByAppType props={{ ...props, values }} variants={individualMenuVariants} />
}

export default IndividualParamsSummary
